import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/vacations/Hero";
import Content1 from "../../sections/vacations/Content1";
import Content2 from "../../sections/vacations/Content2";
import Content5 from "../../sections/vacations/Content5";
import CTA from "../../components/CTA/CTA";

const Vacations = () => {
  return (
    <>
      <PageWrapper>
        <Content5 className="pt-26 pt-lg-34 pb-10 pb-sm-5 pb-lg-14" />
        <Content1 className="pt-11 pt-lg-34 pb-10 pb-sm-5 pb-lg-14" />
        <Hero className="pt-11 pb-8 pb-lg-33" />
        <Content2 className="pb-0 pb-lg-31" />
        <CTA className="bg-buttercup py-10" />
      </PageWrapper>
    </>
  );
};
export default Vacations;
